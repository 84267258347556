






























































import { ProfileModel } from '@/models/profile-model'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'check-before-deleting-dialog': () =>
      import('@/modules/community-organize/components/dao-detail-setting/dialogs/check-before-deleting-dialog.vue'),
  },
})
export default class UserItem extends Vue {
  // @Prop({ default: false }) isAdmin!: boolean
  @Prop({ default: false }) hasDelete!: boolean
  @Prop({ default: false }) isOwnerDao!: boolean
  @Prop({ default: false }) isAdmin!: boolean
  @Prop({}) member!: ProfileModel
  @Ref('confirmDeleteUserDialog') confirmDeleteUserDialog!: any

  async openConfirmDeleteUserDialog() {
    const ok = await this.confirmDeleteUserDialog.open()
    if (ok) {
      this.$emit('onDeleteUser', this.member)
    }
  }

  get canRemoveUser() {
    return this.member.role !== 'admin' && (this.isOwnerDao || (this.isAdmin && this.member.role === 'user'))
  }
  walletStore = walletStore

  get isAccessibleRole() {
    return ['admin', 'contributor'].includes(this.member.role || ' ')
  }

  items = ['contributor', 'user']
  get isOnDutyPerson() {
    return walletStore.userProfile?._id === this.member._id
  }
}
