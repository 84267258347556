var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-item d-flex",class:_vm.$vuetify.breakpoint.smOnly ? 'px-2' : ''},[_c('div',{staticClass:"d-flex gap-2"},[_c('avatar',{attrs:{"size":_vm.$vuetify.breakpoint.smOnly ? 43 : 56,"profile":_vm.member}}),_c('div',[_c('div',{staticClass:"d-flex align-center gap-2"},[_c('div',{staticClass:"username line-clamp-1"},[_vm._v(" "+_vm._s(_vm._f("_get")(_vm.member,'username'))+" ")]),(_vm.isAccessibleRole)?_c('div',{staticClass:"admin-label text-capitalize"},[_vm._v(_vm._s(_vm._f("_get")(_vm.member,'role')))]):_vm._e(),(_vm.walletStore.userProfile && _vm.walletStore.userProfile._id === _vm.member._id)?_c('div',{staticClass:"owner-item-label"},[_vm._v(" You ")]):_vm._e()]),_c('div',{staticClass:"wallet-address line-clamp-1"},[_vm._v("@"+_vm._s(_vm._f("_get")(_vm.member,'unique_id')))])])],1),_c('div',{staticClass:"d-flex align-center gap-2"},[(_vm.isAdmin && _vm.member.role !== 'admin' && !_vm.member.loading)?_c('v-select',{staticClass:"role-select c-select-custom border-radius-10",attrs:{"disabled":!_vm.isOwnerDao || _vm.member.blockUserLoading,"filled":"","solo":"","hide-details":"","items":_vm.items,"value":_vm.member.role,"background-color":"primary darken-1","menu-props":{
        rounded: 'b-lg',
        contentClass: 'menu-no-radius',
        offsetY: true,
      }},on:{"change":function($event){return _vm.$emit('onChangeRole', { member: _vm.member, role: $event })}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-capitalize role-item"},[_vm._v(" "+_vm._s(item)+" ")])]}},{key:"selection",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-capitalize role-item",class:item === 'contributor' ? 'primary--text' : 'white--text'},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,1207215537)}):(_vm.isAdmin && _vm.member.role !== 'admin')?_c('div',{staticClass:"role-select flex-box-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"32"}})],1):_vm._e(),(_vm.member.role !== 'admin')?_c('v-btn',{attrs:{"disabled":!_vm.canRemoveUser || _vm.member.loading,"icon":"","loading":_vm.member.blockUserLoading},on:{"click":function($event){return _vm.openConfirmDeleteUserDialog()}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-close")])],1):_vm._e()],1),_c('check-before-deleting-dialog',{ref:"confirmDeleteUserDialog",attrs:{"profile":_vm.member}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }